import { ConversationId, Message, MessageId } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";

export async function message__client__edit(p: {
  conversationId: ConversationId;
  messageId: MessageId;
  shallowChanges: Pick<Partial<Message>, "reactions" | "text">;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const nowMS = Date.now();

  await h._BatchRunner.executeBatch([
    await h.Message.updateShallow(
      { id: p.messageId, doc: { ...p.shallowChanges, updatedAtMS: nowMS } },
      { returnBatchTask: true }
    )
  ]);
}

// i18n certified - complete
