import { getUniversalHelpers } from "../../helpers";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";

export async function message__server__getAfterMSFailsafe(p: { conversationId: string; afterMS: number; selfAccountId: string }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return await h.Message.query({
    where: [{ createdAtMS: [">=", p.afterMS] }, { conversationId: ["==", p.conversationId] }],
    orderBy: [{ pathObj: { createdAtMS: true }, dir: "desc" }]
  }).then(a => a.docs);
}

message__server__getAfterMSFailsafe.auth = async (req: any) => {
  await validateTokenAndEnsureSelfAccountIdMatches(req);
};
