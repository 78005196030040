import { getUniversalHelpers } from "../../helpers";

export function message__client__getBeforeMS(p: { conversationId: string; beforeMS: number; limit: number }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return h.Message.query({
    where: [{ createdAtMS: ["<", p.beforeMS] }, { conversationId: ["==", p.conversationId] }, { deletedAtMS: ["==", 0] }],
    orderBy: [{ pathObj: { createdAtMS: true }, dir: "desc" }],
    limit: p.limit
  }).then(a => a.docs);
}
