import { getUniversalHelpers } from "../../helpers";

const IMAGE_DIRECTORY = "chat_uploads/";

export function message__client__uploadImage(p: { name: string; blob: any }) {
  const { app } = getUniversalHelpers();
  const task = app
    .storage()
    .ref()
    .child(IMAGE_DIRECTORY + Date.now() + p.name)
    .put(p.blob);

  return task;
}

// i18n certified - complete
