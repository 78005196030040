import { EMOJIS } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function message__client__setReaction(p: {
  messageId: string;
  reaction: EMOJIS;
  accountId: string;
  state: boolean;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  await h.Message.update({
    id: p.messageId,
    doc: { reactions: { [p.accountId]: { [p.reaction]: p.state ? true : h._MagicDeleteValue } } }
  });
}

// i18n certified - complete
