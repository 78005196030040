import { getUniversalHelpers } from "../../helpers";
import { firestoreLiftQuerySubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function message__client__getAfterMSSubscription(p: { conversationId: string; afterMS: number }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return firestoreLiftQuerySubToBifrostSub(
    h.Message.querySubscription({
      where: [{ createdAtMS: [">=", p.afterMS] }, { conversationId: ["==", p.conversationId] }],
      orderBy: [{ pathObj: { createdAtMS: true }, dir: "desc" }]
    })
  );
}
