import { AccountId, CONVERSATION_TYPES, Conversation, MessageId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { validateSelfAccountId, validateToken } from "../../internal-utils/server-auth";
import * as express from "express";
import { BatchTask } from "@ollie-sports/firebase";
import { isOrgAdmin, isStaff } from "../../compute";
import _ from "lodash";
import { ObjectKeys } from "../../utils";

export async function message__server__delete(p: { messageIds: MessageId[]; conversationId: string; selfAccountId: AccountId }) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const [messagesRaw, conversation, mostRecentMessagesData] = await Promise.all([
    h.Message.getDocs(p.messageIds),
    h.Conversation.getDoc(p.conversationId),
    await h.Message.query({
      where: [
        {
          conversationId: ["==", p.conversationId]
        },
        {
          deletedAtMS: ["==", 0]
        }
      ],
      orderBy: [
        {
          pathObj: { createdAtMS: true },
          dir: "desc"
        }
      ],
      limit: p.messageIds.length + 1
    })
  ]);

  const messagesToDelete = _.compact(messagesRaw);

  if (!conversation || !messagesToDelete.length) {
    return;
  }

  if (!messagesToDelete.every(m => m?.conversationId === p.conversationId)) {
    throw new Error("Tried to delete message on incorrect conversation!");
  }

  //Ensure permission to delete all messages
  await Promise.all(
    messagesToDelete.map(async message => {
      if (conversation.conversationType === CONVERSATION_TYPES.accounts) {
        if (message.accountId !== p.selfAccountId) {
          throw new Error("User not authorized to delete this message");
        }
      } else if (conversation.conversationType === CONVERSATION_TYPES.team) {
        const team = await h.Team.getDoc(conversation.teamId);
        if (!team) {
          return;
        }
        const isTeamStaff = !!isStaff({ team, accountId: p.selfAccountId });
        if (message.accountId !== p.selfAccountId && !isTeamStaff) {
          throw new Error("User not authorized to delete this message");
        }
      } else {
        const org = await h.Org.getDoc(conversation.orgId);
        if (!org) {
          return;
        }
        const orgAdmin = !!isOrgAdmin([org], p.selfAccountId, org.id);
        if (message.accountId !== p.selfAccountId && !orgAdmin) {
          throw new Error("User not authorized to delete this message");
        }
      }
    })
  );

  const mostRecentMessages = mostRecentMessagesData.docs;
  const nowMS = Date.now();
  let newConversationPartial: Partial<Conversation> = {};

  if (messagesToDelete.some(m => m.id == mostRecentMessages[0].id)) {
    const newLastMessage = mostRecentMessages.find(m => !messagesToDelete.map(a => a.id).includes(m.id));
    if (!newLastMessage) {
      throw new Error("Unable to determine most recent message!");
    }
    newConversationPartial = {
      updatedAtMS: newLastMessage.createdAtMS,
      lastMessageId: newLastMessage.id
    };
  }

  const batchTasks: BatchTask[] = [];

  await Promise.all(
    messagesToDelete.map(async m => {
      batchTasks.push(
        await h.Message.update(
          {
            id: m.id,
            doc: {
              updatedAtMS: nowMS,
              deletedAtMS: nowMS,
              imageUri: h._MagicDeleteValue,
              videoThumbnailUri: h._MagicDeleteValue,
              videoUri: h._MagicDeleteValue
            }
          },
          { returnBatchTask: true }
        )
      );

      if (m.pollId) {
        batchTasks.push(await h.Poll.delete({ id: m.pollId }, { returnBatchTask: true }));
      }
    })
  );

  if (ObjectKeys(newConversationPartial).length) {
    batchTasks.push(
      await h.Conversation.update(
        {
          id: p.conversationId,
          doc: newConversationPartial
        },
        { returnBatchTask: true }
      )
    );
  }

  await h._BatchRunner.executeBatch(batchTasks);
  // SERVER_ONLY_TOGGLE
}

message__server__delete.auth = async (req: express.Request) => {
  await validateSelfAccountId(req, req.body.selfAccountId);
};

// i18n certified - complete
