import { ConversationId, Message, MessageId, Poll } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { PaginatedSubscriptionReturnType, getSubscriptionWithPaginatedFetchMore } from "../../internal-utils/bifrostHelpers";

export async function message__client__conversationMessagesFetchForFocusedMessage(p: {
  conversationId: ConversationId;
  focusedMessageId: MessageId;
}): Promise<{ messages: Message[]; polls: Poll[] }> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const [messagesBeforeFocusedMessageData, messagesAfterAndIncludingFocusedMessageData] = await Promise.all([
    h.Message.query({
      where: [{ conversationId: ["==", p.conversationId] }, { deletedAtMS: ["==", 0] }],
      _internalStartAfterDocId: p.focusedMessageId,
      orderBy: [{ pathObj: { createdAtMS: true }, dir: "desc" }],
      limit: 20
    }),
    h.Message.query({
      where: [{ conversationId: ["==", p.conversationId] }, { deletedAtMS: ["==", 0] }],
      _internalStartAtDocId: p.focusedMessageId,
      orderBy: [{ pathObj: { createdAtMS: true }, dir: "asc" }],
      limit: 20
    })
  ]);

  const messagesBeforeFocusedMessage = messagesBeforeFocusedMessageData.docs;
  const messagesAfterAndIncludingFocusedMessage = messagesAfterAndIncludingFocusedMessageData.docs;
  const messages = [...messagesAfterAndIncludingFocusedMessage.reverse(), ...messagesBeforeFocusedMessage].reverse();

  const pollIds = _.compact(
    messages.map(m => {
      if (m.pollId) {
        return m.pollId;
      }
      return null;
    })
  );

  let polls: Poll[] = [];
  if (pollIds.length) {
    polls = _.compact(await h.Poll.getDocs(pollIds));
  }

  return { messages, polls };
}

// i18n certified - complete
